import { v4 as uuidv4 } from 'uuid';
import {
	ValidatorFor,
	validateObject,
	notBlank,
	maxLength,
	notEmptyString,
	shouldNotContain,
} from '@/utils/validate';
import { Property, PropertyType, Asset, PropertyDependentCombinator } from '@/store/types';
import { MAX_VALUE_LENGTH } from '@/constants';
import State from '@/store/state';
import { assetsGet, propertiesGet } from '@/selectors';
import { noForbiddenTemplateCharacters, ApiProperty } from 'kes-common';

export function create(id: Property['id'] = uuidv4()): Property {
	return {
		dateFormat: null,
		id,
		stableId: uuidv4(),
		type: PropertyType.STRING,
		name: '',
		placeholder: '',
		question: null,
		description: null,
		choiceIds: [],
		fixed: false,
		dependentCombinator: PropertyDependentCombinator.ANY,
		required: true,
		sourceUrl: '',
		parentActionIds: [],
		parentChoiceIds: [],
		referencedAssetType: '',
		identifyingProperty: '',
		inspection: true,
		survey: true,
		position: null,
		hasNotApplicableOption: false,
		allowMultipleAnswers: false,
		hasOtherOption: false,
		caption: null,
		ruleIds: [],
		width: null,
		height: null,
	};
}

const simpleValidate = validateObject<ValidatorFor<ApiProperty>>({
	dateFormat: [],
	id: [],
	stableId: [],
	type: [],
	name: [
		notBlank('Please type a property name'),
		maxLength(MAX_VALUE_LENGTH),
		noForbiddenTemplateCharacters(),
		shouldNotContain('  '),
	],
	placeholder: [notBlank('Please add a placeholder')],
	question: [],
	description: [notEmptyString],
	choiceIds: [],
	fixed: [],
	dependentCombinator: [],
	sourceUrl: [],
	parentActionIds: [],
	parentChoiceIds: [],
	referencedAssetType: [],
	identifyingProperty: [],
	inspection: [],
	required: [],
	survey: [],
	position: [],
	allowMultipleAnswers: [],
	hasNotApplicableOption: [],
	hasOtherOption: [],
	caption: [],
	ruleIds: [],
	width: [],
	height: [],
});

export const validate = (
	property: Property,
	assetId: Asset['id'],
	store: { getState(): State },
): ReturnType<typeof simpleValidate> => {
	let result = simpleValidate(property);
	const state = store.getState();
	const asset = assetsGet(state, assetId);
	// eslint-disable-next-line no-restricted-syntax
	for (const propertyId of asset.propertyIds) {
		if (propertyId !== property.id) {
			const storeProperty = propertiesGet(state, propertyId);
			if (storeProperty.placeholder === property.placeholder) {
				result = { ...result, placeholder: 'This placeholder is already in use' };
				break;
			}
			if (storeProperty.name === property.name) {
				result = { ...result, name: 'This name is already in use' };
				break;
			}
		}
	}
	return result;
};
