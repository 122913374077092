// eslint-disable-next-line max-classes-per-file
import { ApiAssetFilter } from 'kes-common';

/* eslint-disable max-classes-per-file */
export type UUID = string;

/* Category */
export interface Category {
	readonly id: UUID;
	readonly isDefault: boolean;
	readonly stableId: UUID;
	readonly name: string;
	readonly assetTypeIds: Asset['id'][];
	readonly fixed: boolean;
	readonly type: 'FLAG' | 'FORM' | 'SURVEY';
	readonly info: string | null;
}

/* Asset */
export interface Asset {
	readonly id: UUID;
	readonly info: string | null;
	readonly isDefault: boolean;
	readonly stableId: UUID;
	readonly name: string;
	readonly repeating: boolean;
	readonly propertyIds: Property['id'][];
	readonly fixed: boolean;
	readonly redFlagPropertyId: UUID | null;
	readonly survey: boolean;
	readonly assetFilterIds: ApiAssetFilter['id'][];
	readonly type: 'FLAG' | 'FORM' | 'SURVEY';
}

/* Property */
export interface Property {
	readonly id: UUID;
	readonly stableId: UUID;
	readonly name: string;
	readonly placeholder: string;
	readonly question: string | null;
	readonly description: string | null;
	readonly fixed: boolean;
	readonly dependentCombinator: PropertyDependentCombinator;
	readonly required: boolean;
	readonly type: PropertyType;
	readonly sourceUrl: string;
	// Used by single and multiple select questions
	readonly choiceIds: Choice['id'][];
	readonly parentActionIds: string[];
	readonly parentChoiceIds: Choice['id'][];
	readonly unpushed?: boolean;
	readonly lastExportDate?: Date | null;
	readonly referencedAssetType: UUID | null;
	readonly identifyingProperty: UUID | null;
	readonly inspection: boolean;
	readonly survey: boolean;
	readonly position: number | null;
	readonly hasNotApplicableOption: boolean;
	readonly allowMultipleAnswers: boolean;
	readonly hasOtherOption: boolean;
	readonly caption: string | null;
	readonly dateFormat: string | null;
	readonly ruleIds: string[];
	readonly width: number | null;
	readonly height: number | null;
}

/* PropertyDefinition */
export enum PropertyType {
	DATE = 'DATE',
	RICH_TEXT = 'RICH_TEXT',
	STRING = 'STRING',
	SINGLE_SELECT = 'SINGLE_SELECT',
	DECIMAL = 'DECIMAL',
	MULTI_SELECT = 'MULTI_SELECT',
	SINGLE_SUBSTANCE = 'SINGLE_SUBSTANCE',
	MULTI_SUBSTANCE = 'MULTI_SUBSTANCE',
	IMAGE = 'IMAGE',
	LOCATIONS = 'LOCATIONS',
}

export enum PropertyDependentCombinator {
	ANY = 'ANY',
	ALL = 'ALL',
}

export enum ReportTextSource {
	ANSWER = 'ANSWER',
	ALTERNATE = 'ALTERNATE',
	FALSE = 'FALSE',
}

export interface Choice {
	readonly id: UUID;
	readonly stableId: UUID;
	readonly name: string;
	readonly position: number;
	readonly description: string | null;
	readonly reportText: string | null;
	readonly computed: boolean;
	readonly dependentPropertyIds: string[];
	readonly reportTextSource: ReportTextSource;
}

export interface Report {
	readonly name: string;
	readonly template: string;
	readonly updatedAt: string;
	readonly latestUpdateClientId: string | null;
}

export interface ReportValidationError {
	readonly lineNumber: number;
	readonly column: number;
	readonly message: string;
}

export class MendixUnavailableError extends Error {
	public constructor(message?: string) {
		super(message); // 'Error' breaks prototype chain here
		Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
	}
}

export class AuthorizationError extends Error {
	public constructor(message?: string) {
		super(message); // 'Error' breaks prototype chain here
		Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
	}
}

export class ValidationError extends Error {
	public constructor(message?: string) {
		super(message); // 'Error' breaks prototype chain here
		Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
	}
}

/* RuleSet */
export interface RuleSet {
	readonly id: string;
	readonly name: string;
	readonly rules: Rule[];
}

/* Rule */
export interface Rule {
	readonly id: string;
	readonly name: string;
	readonly description: string;
	readonly checkType: 'MIN' | 'MAX' | 'EQUALS' | 'RANGE';
	readonly checkValue: number;
	readonly upperBound: number | null;
	readonly check: string | null;
}
